@use '@angular/material' as mat;
@use './theming/' as app;
@use 'sass:map';

@include mat.core();
@include mat.all-component-typographies();

$app-primary: mat.m2-define-palette(app.$md-primary);
$app-accent: mat.m2-define-palette(app.$md-accent);
$app-accent-dark: mat.m2-define-palette(app.$md-accent-dark);

$my-typography: mat.m2-define-typography-config($font-family: '"Source Sans Pro", "Helvetica Neue", sans-serif',
    $body-2: mat.m2-define-typography-level($font-size: 16px,
      $line-height: 1.3,
    ),
    $button: mat.m2-define-typography-level($font-size: 16px,
    ),
    $headline-6: mat.m2-define-typography-level(18px, 16px, 600),
    $headline-5: mat.m2-define-typography-level(22px, 20px, 400),
  );
@include mat.typography-hierarchy($my-typography);

// Use your own "app" define-light-theme function instead of "mat" one.
$app-theme: app.define-light-theme(((color: (primary: $app-primary,
          accent: $app-accent,
        ),
        typography: $my-typography,
      )));
$app-theme-dark: app.define-dark-theme(((color: (primary: $app-primary,
          accent: $app-accent-dark,
        ),
        typography: $my-typography,
      )));
@include mat.all-component-themes($app-theme);
@include mat.strong-focus-indicators-theme($app-theme);

.theme-dark {
  @include mat.all-component-colors($app-theme-dark);
  @include mat.strong-focus-indicators-color(white);
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', 'Helvetica Neue', sans-serif;
  /* overflow: auto; commented because it was pushing the footer down and creating lots of blankspace */
  height: 100vh;
  //color: mat.get-color-from-palette(app.$md-accent, 700);
}

input {
  font-size: 16px !important;
}

.customTooltip {
  color: black;
  background-color: white;
}

// * {
//   outline: 1px solid #f00 !important;
// }

//prevent button text from wrapping to new line
button,
.mdc-button {
  white-space: nowrap;
}

div .mat-mdc-tab {
  font-weight: 600;
}